import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  AssessmentDto,
  AssessmentSubmissionDto,
  AssignmentDto,
  AssignmentResultsDto,
  AssignmentType,
  SessionEvent,
} from './eols-assessment-service.dtos';

export const assignmentUrl = '/v2/assignment';

export const fetchAssignments = (courseSectionId: string): Promise<AssignmentDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(assignmentUrl, { courseSectionId }));
  });
};

// This API return full detail of an assignment
export const fetchAssignment = (assignmentId: string): Promise<AssignmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/v2/assignment/${assignmentId}`);
  });
};

export const fetchAssessmentByAssignmentIdAndUserId = (userId: string, assignmentId: number): Promise<AssessmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/v2/assessment', { userId, assignmentId }));
  });
};

export const fetchAssessmentsByAssignmentIdAndUserId = (userId: string, assignmentId: number): Promise<AssessmentDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/v2/assessment', { userId, assignmentId, filter: 'ALL' }));
  });
};

export const fetchAssignmentResults = (assignmentId: number): Promise<AssignmentResultsDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/v2/assignment/${assignmentId}/results`);
  });
};

export const postSubmission = (assessmentSubmission: Partial<AssessmentSubmissionDto>): Promise<AssessmentSubmissionDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/v2/assessment/submission', {
      data: assessmentSubmission
    });
  });
};

export const postCreateAssessmentFromAssignment = (assessment: Partial<AssessmentDto>): Promise<AssessmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/v2/assignment/${assessment.assignmentId}/assessment`, {
      data: assessment
    });
  });
};

export const submitAssessmentScore = (
  assessmentId: number,
  assessmentSubmission: Partial<AssessmentSubmissionDto>,
): Promise<AssessmentSubmissionDto> => {
  const submittedAssessmentSubmission: Partial<AssessmentSubmissionDto> = {
    ...assessmentSubmission,
    assessmentId,
  };
  return postSubmission(submittedAssessmentSubmission);
};

/* As of Oct 2024, the backwards-compatible PUT request that allows the isGradepointIndependentGoals value to be
updated from false to true requires including the value as true in the URL parameter but not having it in the
request payload. Having both passed as true when the database entry is false at creation results in an API error. */
export const eolsPutAssignment = (assignment: Partial<AssignmentDto>, isGradepointIndependentGoals = false): Promise<AssignmentDto> => {
  const putAssignmentUrl = `${assignmentUrl}?isGradepointIndependentGoals=${isGradepointIndependentGoals}`;
  const putAssignment = assignment;

  if (assignment.assignmentType === AssignmentType.INTERACTIVE_REVIEW
    && assignment.isGradepointIndependentGoals !== undefined
    && isGradepointIndependentGoals !== assignment.isGradepointIndependentGoals) {
    delete putAssignment.isGradepointIndependentGoals;
  }

  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(putAssignmentUrl, { data: putAssignment });
  });
};

export const eolsPostAssignment = (assignment: Partial<AssignmentDto>, isGradepointIndependentGoals = false): Promise<AssignmentDto> => {
  const postAssignmentUrl = `${assignmentUrl}?isGradepointIndependentGoals=${isGradepointIndependentGoals}`;
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(postAssignmentUrl, { data: assignment });
  });
};

export const postProvisionStudentAssignmentData = (sessionEvent: SessionEvent): Promise<void> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/v2/assessment/events/session', { data: sessionEvent });
  });
};
